import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { GlobalStyles } from 'twin.macro';
import { rhythm, scale } from '../utils/typography';
import { H1, H3 } from './typography/Heading';

const Wrapper = styled.div`
  color: hsla(0, 0%, 0%, 0.9);
  font-family: 'Merriweather', 'Georgia', serif;
  word-wrap: break-word;
  font-kerning: normal;
  min-height: 100vh;
  font-feature-settings: "kern", "liga", "clig", "calt";
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 100%;
  line-height: 1.75;

  p {
    padding: 0;
    margin: 0 0 1.75rem;
  }

  a {
    box-shadow: 0 1px 0 0 currentColor;
    color: #007acc;
    text-decoration: none;
  }
`;

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`;

const Layout = (props) => {
  const { location, title, children } = props;
  // eslint-disable-next-line no-undef
  const rootPath = `${__PATH_PREFIX__}/`;
  // eslint-disable-next-line no-undef
  const blogPath = `${__PATH_PREFIX__}/blog/`;
  let header;

  if (location.pathname === rootPath || location.pathname === blogPath) {
    header = (
      <H1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: 'none',
            textDecoration: 'none',
            color: 'inherit',
          }}
          to={location.pathname === blogPath ? '/privateblog/' : '/'}
        >
          {title}
        </Link>
      </H1>
    );
  } else {
    header = (
      <H3
        style={{
          fontFamily: 'Montserrat, sans-serif',
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: 'none',
            textDecoration: 'none',
            color: 'inherit',
          }}
          to="/blog/"
        >
          {title}
        </Link>
      </H3>
    );
  }
  return (
    <Wrapper>
      <GlobalStyles />
      <div
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
      </div>
      <Footer>
        ©
        {' '}
        {new Date().getFullYear()}
        , Built with
        {' '}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </Footer>
    </Wrapper>
  );
};

export default Layout;
